const seeMyWorkCopy = {
  main: {
    en: `Until then, you can see my work here`,
    pl: `Do tego czasu, moje prace możesz zobaczyć tutaj`
  },
  cta: {
    en: `See my work`,
    pl: `Zobacz moje prace`
  }
}

export default seeMyWorkCopy